<template>
  <div>
    <!-- <div class="bd-highlight mb-1">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input placeholder="Search" @input="searchDataTable()" v-model="searchItems" />
      </b-input-group>
    </div> -->
    <b-alert :show="isNodata" class="w-50 w-100" variant="danger">
      <!-- <h4 class="alert-heading">Danger</h4> -->
      <div class="alert-body text-center">
        <feather-icon icon="InfoIcon" class="mr-50" />
        <span>ไม่มีข้อมูล</span>
      </div>
    </b-alert>
    <div class="row">
      <div v-for="(item, index) in items" :key="index" class="col-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="item-img" v-if="item.urlImage.length">
            <swiper :options="swiperOptions" class="swiper-lazy-loading">
              <swiper-slide v-for="(image, index) in item.urlImage" :key="index">
                <b-img :src="image" fluid center class="swiper-lazy card-img-top mw-100" style="max-width: 100%; height: 100%; object-fit: cover" />
                <div v-if="index != 0">
                  <div class="swiper-lazy-preloader swiper-lazy-preloader-white" />
                </div>
              </swiper-slide>
              <div slot="pagination" class="swiper-pagination" />
              <div slot="button-next" class="swiper-button-next swiper-button-white" />
              <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
            </swiper>
          </div>
          <div v-else>
            <div class="item-img" style="max-width: 100%; height: 300px">
              <b-overlay show spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
              <!-- <b-img src="https://zosepcar.cl/images/cargando.gif" class="card-img-top" style="width: 100%; height: 100%" /> -->
            </div>
          </div>

          <div class="card-body">
            <div class="card-title h6 item-description">
              <!-- <b-badge variant="primary">
                  <feather-icon icon="HashIcon" class="mr-25" />
                  <span>{{ item.category }}</span>
                </b-badge> -->
              {{ item.topic }}
            </div>
            <p class="card-text item-description">
              {{ item.detail }}
            </p>
            <div class="d-flex">
              <div v-for="(tags, index) in item.tag" :key="index" class="card-text">
                <b-badge class="col-auto" variant="primary">
                  <feather-icon icon="HashIcon" class="mr-25" />
                  <span>{{ tags }}</span>
                </b-badge>
              </div>
            </div>
          </div>

          <div class="list-group list-group-flush">
            <!-- <div class="list-group-item">
                <a :href="item.weblink != '-' ? item.weblink : ''" target="_blank"><feather-icon icon="MousePointerIcon" class="mr-25" /> Web Link</a>
              </div>
              <div class="list-group-item">
                <a href="" target="_blank"><feather-icon icon="MapPinIcon" class="mr-25" />GPS</a>
              </div> -->
          </div>
          <div class="card-footer d-flex justify-content-between flex-wrap">
            <div class="flex-wrap">
              <small class="text-muted">
                Create: <cite>{{ formatToThaiTime(item.showDate) }}</cite>
              </small>
            </div>
            <div class="flex-wrap">
              <small class="text-muted">
                Update: <cite>{{ formatTofromNow(item.registerDate) }}</cite>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  v-show="false">
      <b-nav class="navbar navbar-light shadow mb-1 d-flex justify-content-between bd-highlight flex-wrap">
        <!-- <div class="bd-highlight d-flex align-items-center text-nowrap">Showing 1 to: <b-form-select v-model="perPage" :options="perPageOption" size="sm" @change="handleItemsPerPageChange" /> of {{ totalItems }}</div> -->
        <div>Showing {{ currentPage }} to {{ perPage }} of {{ totalPage }} entries</div>
        <div class="bd-highlight">
          <!-- <div v-show="items.length !== totalPage">
          <b-button @click="showmore()">{{ items.length }}/{{ totalPage }}</b-button>
        </div> -->

          <b-pagination v-model="currentPage" :total-rows="totalPage" :per-page="perPage" @input="handlePageChange" class="mb-0" first-text="First" prev-text="Prev" next-text="Next" align="left" last-text="Last" hide-goto-end-buttons />
        </div>
      </b-nav>
    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

  import 'swiper/css/swiper.css';
  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');

  dayjs.extend(buddhistEra);
  const relativeTime = require('dayjs/plugin/relativeTime');

  dayjs.extend(relativeTime);

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        categoryName: 'service',
        currentPage: 1, // จำนวนรายการที่แสดงในแต่ละหน้า
        perPage: 8, // จำนวนรายการที่แสดงในแต่ละหน้า
        totalPage: 0, // จำนวนรายการ
        playLoad: true,
        isNodata: false,
        // perPageOption: [
        //   { value: 8, text: 8 },
        //   { value: 10, text: 10 },
        //   { value: 15, text: 15 },
        // ],
        items: [],
        searchItems: '',

        API: '',
        access_token: '',
        swiperOptions: {
          lazy: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      };
    },

    created() {
      this.getDataTable();
    },
    methods: {
      showmore() {
        this.currentPage += 1;
        this.getDataTable();
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.items = [];
        this.getDataTable();
      },
      handleItemsPerPageChange() {
        // กรณีที่ต้องการให้เลือกจำนวนรายการที่แสดงเปลี่ยนแปลงทันที
        this.currentPage = 1;
        this.items = [];
        this.getDataTable();
      },
      formatTofromNow(date) {
        // เวลาที่อัพเดท
        return dayjs().to(dayjs(date));
      },
      formatToThaiTime(date) {
        // เปลี่ยนวันที่ไทย
        return dayjs.tz(date, 'Asia/Bangkok').format('DD-MM-BBBB HH:ss');
      },
      async searchDataTable() {
        this.playLoad = true;
        if (this.searchItems) {
          const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
          this.$store
            .dispatch('getData', `announce/${userId}?category=${this.categoryName}&topic=${this.searchItems}&_page=1`)
            .then(async (res) => {
              console.log(res.message);
              await res.message.result.forEach((el) => {
                const image = [];

                if (el.announceImage.length) {
                  el.announceImage.map(async (el) => {
                    image.push(await this.$store.dispatch('getImageURL', el).then((res) => res));
                  });
                } else {
                  image.push('https://th.bing.com/th/id/OIP.MvgjeMBHxcKil9_H4jQcvgAAAA?rs=1&pid=ImgDetMain');
                }

                el.urlImage = image;
              });
              this.items = res.message.result;
              //this.totalPage = res.message.total;
              
              this.playLoad = false;
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
          console.log('New table');
          this.getDataTable();
        }
      },
      async getDataTable() {
        this.playLoad = true;
        this.$store
          .dispatch('get_announce_filter', this.categoryName)
          .then(async (res) => {
            await res.forEach((el) => {
              const image = [];

              if (el.announceImage.length) {
                el.announceImage.map(async (el) => {
                  image.push(await this.$store.dispatch('getImageURL', el).then((res) => res));
                });
              } else {
                image.push('https://th.bing.com/th/id/OIP.MvgjeMBHxcKil9_H4jQcvgAAAA?rs=1&pid=ImgDetMain');
              }
              el.urlImage = image;
            });
            this.items = res;
            this.totalPage = res.length;
            this.isNodata = this.items.length == 0 ? true : false;
            this.playLoad = false;
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/swiper.scss';
  .item-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
